/* eslint-disable import/order */

import AuthLayout from '~layouts/Auth';

// auth & password
import Login from '~pages/dashboard/login';
import RecoveryPassword from '~pages/dashboard/recover-password/index';
import ResetPassword from '~pages/dashboard/reset-password/index';

const screens = [
  // auth & password
  {
    pathname: '/dashboard/login',
    screen: Login,
    layout: AuthLayout,
  },
  {
    pathname: '/dashboard/recover-password',
    screen: RecoveryPassword,
    layout: AuthLayout,
  },
  {
    pathname: '/dashboard/reset-password',
    screen: ResetPassword,
    layout: AuthLayout,
  },
];

export default screens;
