import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';

const CreateProductCategoryModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Product/CreateProductCategoryModal')
);
const SellerProductsListModal = webCore.helpers.dynamic(
  () => import('~components/Dashboard/SellerProductsListModal')
);
const CreateEditDeliveryPartnerModal = webCore.helpers.dynamic(
  () =>
    import('~components/Admin/DeliveryPartners/CreateEditDeliveryPartnerModal')
);
const DeleteSellerModal = webCore.helpers.dynamic(
  () => import('~components/DeleteSellerModal')
);

const DeleteDeliverModal = webCore.helpers.dynamic(
  () => import('~components/DeleteDeliveryModal')
);

const ProductDescriptionModal = webCore.helpers.dynamic(
  () => import('~components/ProductDescriptionModal')
);

const ProductPhotosModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingProducts/ProductPhotosModal')
);
const ProductDenyModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingProducts/ProductDenyModal')
);
const SellerVerificationModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingSellers/VerificationModal')
);

const SellerDenyModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingSellers/SellerDenyModal')
);

const FieldValueAutofillModal = webCore.helpers.dynamic(
  () => import('~components/Admin/CategoryForm/FieldValueAutofillModal')
);

const ProductDeniedOrderModal = webCore.helpers.dynamic(
  () => import('~components/Admin/ReturnedProduct/ProductDeniedOrderModal')
);

const ProductOrderDetailsModal = webCore.helpers.dynamic(
  () => import('~components/Admin/ReturnedProduct/ProductOrderDetailsModal')
);

const SellerFullInfoModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Sellers/DetailPage/FullInfoModal')
);

const ProductValueApproveModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingProducts/ProductValueApproveModal')
);

const AdminCustomerPersonalInfoModal = webCore.helpers.dynamic(
  () => import('~components/Admin/UsersLists/DetailPage/PersonalInfoModal')
);

const AdminCustomerCompanyDetailsModal = webCore.helpers.dynamic(
  () => import('~components/Admin/UsersLists/DetailPage/CompanyDetailsModal')
);

const AdminCustomerAddNewUserModal = webCore.helpers.dynamic(
  () => import('~components/Admin/UsersLists/AddNewUserModal')
);

const AdminCustomerSeeSharedAccounts = webCore.helpers.dynamic(
  () =>
    import(
      '~components/Admin/UsersLists/DetailPage/FavoritesPage/SeeSharedAccounts'
    )
);

const AdminChangePasswordModal = webCore.helpers.dynamic(
  () => import('~components/Admin/ChangePasswordModal')
);

const AdminChangePasswordSuccessfullyModal = webCore.helpers.dynamic(
  () => import('~components/Admin/ChangePasswordSuccessfullyModal')
);

const ViewPriceLimitModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PriceList/ViewPriceLimitModal')
);

const AdminCreateEditPriceLimitModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PriceList/CreateEditPriceLimitModal')
);

const AdminDuplicateCategoryModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Category/Duplicate/Modal')
);

const AdminDuplicateCategoryAddToExistingModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Category/Duplicate/AddToExistingModal')
);

const CreateEditCategoryModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Category/CreateEditCategoryModal')
);

const PendingOrdersInfoModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingOrders/PendingOrdersInfoModal')
);

const PendingOrdersDeniedModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingOrders/ProductOrdersDeniedModal')
);

const SingleTrackModal = webCore.helpers.dynamic(
  () =>
    import('~components/Admin/DeliveryPartners/OrderDetails/SingleTrackModal')
);

const MultilangModal = webCore.helpers.dynamic(
  () => import('~components/Admin/PendingProducts/MultilangModal')
);

const AddEditSlideModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Banners/Slides/AddEditSlideModal')
);

const AddEditBadgeModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Badges/AddEditBadgeModal')
);

const DeleteBadgeModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Badges/DeleteBadgeModal')
);

const OrderItemDetailsModal = webCore.helpers.dynamic(
  () =>
    import('~components/Admin/OrdersList/DetailsCards/OrderItemDetailsModal')
);

const PreviewSlideModal = webCore.helpers.dynamic(
  () => import('~components/Admin/Banners/Slides/PreviewSlideModal')
);

const EndThreadModal = webCore.helpers.dynamic(
  () => import('~components/Chat/EndThreadModal')
);

const AddEditPushNotificationModal = webCore.helpers.dynamic(
  () =>
    import('~components/Admin/PushNotifications/AddEditPushNotificationModal')
);

const SelectGrantOptionModal = webCore.helpers.dynamic(
  () => import('~components/Admin/CustomerGrants/SelectGrantOptionModal')
);

const modals = {
  [webApp.constants.ENUM_MODAL_CREATE_PRODUCT_CATEGORY]:
    CreateProductCategoryModal,
  [webApp.constants.ENUM_MODAL_EDIT_SELLER_PRODUCTS_LIST]:
    SellerProductsListModal,
  [webApp.constants.ENUM_MODAL_CREATE_EDIT_DELIVERY_PARTNER]:
    CreateEditDeliveryPartnerModal,
  [webApp.constants.ENUM_MODAL_DELETE_SELLER]: DeleteSellerModal,
  [webApp.constants.ENUM_MODAL_DELETE_DELIVERY]: DeleteDeliverModal,
  [webApp.constants.ENUM_MODAL_PRODUCT_DESCRIPTION]: ProductDescriptionModal,
  [webApp.constants.ENUM_MODAL_PRODUCT_PHOTOS]: ProductPhotosModal,
  [webApp.constants.ENUM_MODAL_DENY_PRODUCT]: ProductDenyModal,
  [webApp.constants.ENUM_MODAL_SELLER_VERIFICATION]: SellerVerificationModal,
  [webApp.constants.ENUM_MODAL_SELLER_DENY]: SellerDenyModal,
  [webApp.constants.ENUM_MODAL_FORMFIELD_SEARCH_RESULTS]:
    FieldValueAutofillModal,
  [webApp.constants.ENUM_MODAL_PRODUCT_DENIED_ORDER]: ProductDeniedOrderModal,
  [webApp.constants.ENUM_MODAL_PRODUCT_ORDER_DETAILS]: ProductOrderDetailsModal,
  [webApp.constants.ENUM_MODAL_SELLER_FULL_INFO]: SellerFullInfoModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CUSTOMER_PERSONAL_INFO]:
    AdminCustomerPersonalInfoModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CUSTOMER_COMPANY_INFO]:
    AdminCustomerCompanyDetailsModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CUSTOMER_ADD_NEW_USER]:
    AdminCustomerAddNewUserModal,
  [webApp.constants.ENUM_MODAL_PRODUCT_VALUE_APPROVE]: ProductValueApproveModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CUSTOMER_SEE_SHARED_ACCOUNTS]:
    AdminCustomerSeeSharedAccounts,
  [webApp.constants.ENUM_MODAL_ADMIN_CHANGE_PASSWORD_MODAL]:
    AdminChangePasswordModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CHANGE_PASSWORD_SUCCESSFULLY_MODAL]:
    AdminChangePasswordSuccessfullyModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CREATE_EDIT_PRICE_LIMIT]:
    AdminCreateEditPriceLimitModal,
  [webApp.constants.ENUM_MODAL_ADMIN_VIEW_PRICE_LIMIT]: ViewPriceLimitModal,
  [webApp.constants.ENUM_MODAL_ADMIN_DUPLICATE_CATEGORY]:
    AdminDuplicateCategoryModal,
  [webApp.constants.ENUM_MODAL_ADMIN_DUPLICATE_CATEGORY_ADD_TO_EXISTING]:
    AdminDuplicateCategoryAddToExistingModal,
  [webApp.constants.ENUM_MODAL_ADMIN_CREATE_EDIT_CATEGORY]:
    CreateEditCategoryModal,
  [webApp.constants.ENUM_MODAL_ADMIN_PENDING_ORDERS_INFO]:
    PendingOrdersInfoModal,
  [webApp.constants.ENUM_MODAL_ADMIN_PENDING_ORDERS_DENIED]:
    PendingOrdersDeniedModal,
  [webApp.constants.ENUM_MODAL_ADMIN_DELIVERY_PARTNERS_SINGLE_TRACK_ORDERS]:
    SingleTrackModal,
  [webApp.constants.ENUM_MODAL_ADMIN_PRODUCT_MULTILANG]: MultilangModal,
  [webApp.constants.ENUM_MODAL_ADMIN_ADD_EDIT_SLIDE]: AddEditSlideModal,
  [webApp.constants.ENUM_MODAL_ADMIN_ADD_EDIT_BADGE]: AddEditBadgeModal,
  [webApp.constants.ENUM_MODAL_DELETE_BADGE]: DeleteBadgeModal,
  [webApp.constants.ENUM_MODAL_ADMIN_ORDER_ITEM_DETAILS]: OrderItemDetailsModal,
  [webApp.constants.ENUM_MODAL_ADMIN_BANNER_SLIDE_PREVIEW]: PreviewSlideModal,
  [webApp.constants.ENUM_MODAL_END_THREAD]: EndThreadModal,
  [webApp.constants.ENUM_MODAL_ADMIN_ADD_EDIT_PUSH_NOTIFICATION]:
    AddEditPushNotificationModal,
  [webApp.constants.ENUM_MODAL_ADMIN_SELECT_GRANT_OPTION]:
    SelectGrantOptionModal,
};

export default modals;
