import React from 'react';
import { useForm } from 'react-hook-form';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { ErrorMessage } from '@hookform/error-message';
import {
  Heading,
  Text,
  FormControl,
  Button,
  Input,
  Stack,
  Icon,
} from '@chakra-ui/react';

export default function VerifyPassword() {
  const { t } = webCore.contexts.useTranslation();
  const toast = webApp.theme.hooks.useToast();

  const navigate = webApp.router.useNavigate();
  const searchParams = webApp.hooks.useQueryParams();

  const { mutate: resetPassword, isLoading } =
    webCore.coreHooks.user.useResetPassword();

  const {
    register,
    formState: { errors },
    watch,
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: { code: '' },
  });

  const onSubmit = (values) => {
    const onError = (e) => {
      toast({ status: 'error', title: e.message });
    };
    const onSuccess = () => {
      toast({ status: 'success', title: t('Password changed successfully') });
      navigate('/dashboard/login');
    };

    values.identifier = searchParams.identifier;
    resetPassword(values, { onError: onError, onSuccess: onSuccess });
  };
  return (
    <Stack
      as="form"
      maxW="581px"
      w="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      bg="white"
      borderRadius="12px"
      px={11}
      py={8}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Icon as={webApp.theme.icons.Graph} fill="none" w="44px" h="44px" />
      <Stack spacing={2} alignItems="center" justifyContent="center" maxW={291}>
        <Heading as="h3" variant="h3-bold">
          {t('Reset Password')}
        </Heading>
      </Stack>
      <Stack spacing={8} alignItems="center">
        <Text variant="body-reg-lg" color="grey.600" textAlign="center">
          We have sent you an verification code to your{' '}
          <Text as="span" color="blue.50">
            {searchParams.identifier}
          </Text>{' '}
          email address, please enter the code below.
        </Text>

        <FormControl mt={6}>
          <Input
            type="number"
            placeholder="Code"
            bg={`${errors.password ? 'red.50' : 'blue.50'}`}
            borderRadius="8px"
            height={12}
            _focus={{ border: 'none', shadow: 'none' }}
            {...register('code', {
              required: 'Code is required',
              pattern: {
                value: webCore.patterns.ONLY_NUMBERS_PATTERN,
                message: 'There are invalid characters',
              },
              minLength: {
                value: 6,
                message: 'This entry must exceed 6 characters',
              },
              maxLength: {
                value: 6,
                message: 'This entry must exceed 6 characters',
              },
            })}
            color="grey.900"
            fontWeight="500"
            border="none"
          />
          <ErrorMessage
            errors={errors}
            name="code"
            render={({ message }) => (
              <Text variant="body-reg-xs" color="red.500">
                {message}
              </Text>
            )}
          />
        </FormControl>
        <webApp.components.ConfirmPasswordFormControl
          getValues={getValues}
          showConfirmPassword={true}
          errors={errors}
          register={register}
          registerKey="password"
          hideLabel={true}
          spacing={8}
          placeholder={['Password', 'Confirm Password']}
          inputProps={{
            color: 'grey.900',
            fontWeight: '500',
            borderRadius: '8px',
            borderColor: 'primary.50',
            border: 'none',
            bg: `${errors.password ? 'red.50' : 'blue.50'}`,
            height: 12,
            _focus: { border: 'none', shadow: 'none' },
          }}
          iconProps={{
            fill: 'black',
            stroke: 'black',
            w: 5,
            h: 5,
          }}
          watch={watch}
        />
        <Button
          mt={8}
          variant="filled"
          borderRadius="12px"
          w={180}
          size="lg"
          watch={watch}
          isLoading={isLoading}
          _hover={{ bg: 'primary.50', color: 'white.50' }}
          type="submit"
        >
          {t('Reset Password')}
        </Button>
      </Stack>
    </Stack>
  );
}
