/* eslint-disable import/order */

import appBranding from '@happylife-a/branding';
import webApp from '@happylife-a/web-app';

import appConfigContactsAndLinks from '../public/.app-branding/contacts+links.json';
import appConfigBranding from '../public/.app-branding/app-branding.json';
import appConfigMobileApp from '../public/.app-branding/mobile-app.json';
import appConfigTheming from '../public/.app-branding/theming.json';
import appConfigContentPrivacyPolicy from '../public/.app-branding/content/privacy-policy.json';
import appConfigContentTermsConditions from '../public/.app-branding/content/terms-conditions.json';
import appConfigContentDeliveryPolicy from '../public/.app-branding/content/delivery-policy.json';
import appConfigContentReturnPolicy from '../public/.app-branding/content/return-policy.json';

appBranding.initiate({
  branding: appConfigBranding,
  mobileApp: appConfigMobileApp,
  theming: appConfigTheming,
  contactsAndLinks: appConfigContactsAndLinks,
  contentPrivacyPolicy: appConfigContentPrivacyPolicy,
  contentTermsConditions: appConfigContentTermsConditions,
  contentDeliveryPolicy: appConfigContentDeliveryPolicy,
  contentReturnPolicy: appConfigContentReturnPolicy,
});

webApp.theme.initWithBranding(appConfigTheming.theme);
webApp.configure('AdminDashboard');

export { appBranding };
