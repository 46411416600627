import React, { useState } from 'react';
import enums from '@happylife-a/enums';
import webCore from '@happylife-a/web-core';
import Form from '~components/Dashboard/LoginForm';

export default function DashboardLogin() {
  const { mutate: signIn, isLoading } = webCore.coreHooks.user.useSignIn();
  const { t } = webCore.contexts.useTranslation();

  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState(null);
  const onSubmit = (values) => {
    setShowError(false);

    const onError = (e) => {
      setShowError(true);
      setMessage(t(e.message));
    };

    signIn(
      { ...values, role: enums.user.UserRoleEnum.ADMIN },
      { onError: onError }
    );
  };

  return (
    <Form
      isLoading={isLoading}
      onSubmit={onSubmit}
      showError={showError}
      message={message}
    />
  );
}
