import React from 'react';
import webApp from '@happylife-a/web-app';
import { Box, Image, Container, Stack } from '@chakra-ui/react';

export default function DashboardHeader({ children }) {
  return (
    <Box>
      <Box py={4}>
        <Container maxW="7xl">
          <webApp.components.LocalizedLink to="/">
            <Image
              alt="logo"
              src="/.app-branding/logo/generated/svg/original-colored.svg"
              w="56px"
              h="56px"
              objectFit="cover"
            />
          </webApp.components.LocalizedLink>
        </Container>
      </Box>

      <Stack
        minH="100vh"
        flexDirection="column"
        alignItems="center"
        spacing={8}
        pt={121}
        // @TODO: ask designer for color
        bg="blue.50"
      >
        {children}
      </Stack>
    </Box>
  );
}
