import React from 'react';
import { useForm } from 'react-hook-form';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import {
  Stack,
  Icon,
  Text,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from '@chakra-ui/react';

export default function Form({ onSubmit, isLoading }) {
  const { t } = webCore.contexts.useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: { identifier: '' },
  });

  return (
    <>
      <Stack spacing={8} alignItems="center" w={291}>
        <Text variant="body-reg-lg" color="grey.600" textAlign="center">
          {t('Enter mail address that you used while registering.')}
        </Text>
      </Stack>
      <Stack
        pt={14}
        w="full"
        spacing={6}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              height={12}
              // eslint-disable-next-line react/no-children-prop
              children={
                <Icon as={webApp.theme.icons.Email} fill="none" w={5} h={5} />
              }
            />
            <Input
              type="email"
              border="none"
              bg={`${errors.identifier ? 'red.50' : 'blue.50'}`}
              borderRadius="8px"
              height={12}
              _focus={{ border: 'none', shadow: 'none' }}
              {...register('identifier', {
                required: 'This entry is required',
                pattern: {
                  value: webCore.patterns.EMAIL_PATTERN,
                  message: 'Invalid email address',
                },
                minLength: {
                  value: 8,
                  message: 'This entry must exceed 8 characters',
                },
              })}
            />
          </InputGroup>
        </FormControl>
        <Stack alignItems="center" pt={10}>
          <Button
            variant="filled"
            isLoading={isLoading}
            isDisabled={isLoading || watch('identifier') === ''}
            type="submit"
            fontSize="2xl"
            fontWeight={600}
            w={180}
            h={53}
            borderRadius={12}
          >
            {t('Confirm')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
