import webCore from '@happylife-a/web-core';

const AuthLayout = webCore.helpers.dynamic(() => import('~layouts/Auth'));

const HomePage = webCore.helpers.dynamic(() => import('~pages/index'));
const NotFound = webCore.helpers.dynamic(() => import('~pages/404'));

const screens = [
  {
    pathname: '/',
    screen: HomePage,
  },

  // #########################################################
  // #########       DON'T CHANGE THE ORDERING       #########
  // #########################################################

  // the not-found route must be end of the list.
  {
    pathname: '*',
    screen: NotFound,
    layout: AuthLayout,
  },
];

export default screens;
